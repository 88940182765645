import React from 'react';
import {deletePath} from "../../../providers/hooks";
import AddCategory from "./AddCategory";
import Image from "../../elements/edit/Image";
import Checkbox from "../../elements/edit/Checkbox";
import Modal from "../../framework/Modal";
import OptionsModal from "./OptionsModal";


class CategoriesItem extends React.Component   {

    state = {
        settings: false,
        options: false,
        optionsModal: false
    }

    delete = () => {
        var r = window.confirm("Želite da obrišete kategoriju?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "categories." + this.props.item.id}).then((response) => {
            this.setState({...this.state, settings: false});
            this.props.reload();
        })
    }

    deleteOption = (id) => {
        var r = window.confirm("Želite da obrišete opciju?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "category_options." + id}).then((response) => {
            this.props.reload();
        })
    }

    render = () => {
        let item = this.props.item;
        return  <div>
            <Modal
                reload={true}
                open={this.state.optionsModal}
                close={() => this.setState({...this.state, optionsModal: false})}>

                <OptionsModal
                    category={this.props.item.id}
                    reload={this.props.reload}
                    close={() => this.setState({...this.state, optionsModal: false})}
                />
            </Modal>


            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {this.props.collapseIcon}
                    {this.props.handler}
                    {item.name}
                </div>
                <div>
                    <button
                        onClick={() => this.setState({...this.state, options: !this.state.options})}
                        className="btn btn-sm btn-link mr-2">
                        <i className="fa fa-chevron-down" /> Dodatne opcije</button>
                    <button
                        onClick={() => this.setState({...this.state, settings: !this.state.settings})}
                        className="btn btn-primary btn-sm">
                        <i className="fa fa-gear" />
                    </button>
                </div>

            </div>


            {this.state.options &&
            <div className="mt-3 p-2">
                {item.options.map((option) => {
                    return <div key={option.pivot.id} className="d-flex align-items-center mb-2">
                        {option.name}

                        <button
                            onClick={() => this.deleteOption(option.pivot.id)}
                            className="btn btn-sm btn-danger ml-3">Obrišite</button>
                    </div>
                })}
                <button
                    onClick={() => this.setState({...this.state, optionsModal: true})}
                    className="btn btn-primary btn-sm mt-3">Dodajte opciju</button>
            </div>}


            {this.state.settings &&
            <div className="mt-3 p-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <AddCategory item={item} reload={this.props.reload} />
                        </div>
                        <div className="mb-3">
                            <AddCategory parent={item.id} reload={this.props.reload} />
                        </div>
                        <button
                            onClick={this.delete}
                            className="btn btn-danger mb-3">
                            <i className="fa fa-trash" /> Obrišite
                        </button>
                        <div>
                            <label className="mr-3">
                                <Checkbox
                                    path={`categories.${item.id}.hot`}
                                    checked={item.hot} name="hot" /> HOT
                            </label>
                            <label className="mr-3">
                                <Checkbox
                                    path={`categories.${item.id}.action`}
                                    checked={item.action} name="action" /> AKCIJA
                            </label>
                            <label className="mr-3">
                                <Checkbox
                                    path={`categories.${item.id}.new`}
                                    checked={item.new} name="new" /> NOVO
                            </label>
                        </div>

                    </div>
                    <div className="col-md-6">
                        Kliknite na sliku da promenite
                        <div className="border mt-2" style={{maxWidth: 200}}>
                            <Image
                                path={item.id && `categories.${item.id}.image_id`}
                                value={item.image_name}
                                name="image_id" />
                        </div>
                    </div>
                </div>
            </div>}
        </div>;
    };
}
export default CategoriesItem;
