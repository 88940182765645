import React from "react";
import {connect} from "react-redux";
import {pluck, processPrice, storage} from "../../../providers/helpers";
import {getProducts} from "../../../providers/hooks";
import Loader from "../../../views/elements/Loader";
import routes from "../../../app/routes";
import Price from "../../theme/Price";
import Qty from "../product/Qty";
import {Link} from "react-router-dom";
import {deleteFromBasket, emptyBasket, setBasket} from "../../../store/actions/basketActions";
import SettingsButton from "../../fragments/SettingsButton";
import OrderForm from "./OrderForm";
import Looper from "../../Looper";
import RenderOptionProduct from "./RenderOptionProduct";
import elements from "../../../views/cms/options/elements/elements";

class Basket extends React.Component {

    state = {
        loading: true,
        products: []
    }

    delete = (product) => {
        let products = this.state.products.filter(item => item.unique !== product.unique);
        this.props.deleteFromBasket(product);
        this.setState({...this.state, products: products});
    }

    changeQty = (id, qty) => {
        let products = this.state.products.map((item) => {
            if(item.unique !== id) return item;
            item.qty = qty;
            return item;
        });
        this.props.setBasket(products);
        this.setState({...this.state, products: products});
    }

    render = () => {
        if(this.state.loading)       {
            return <Loader height={300} />
        }
        if(this.state.products.length === 0)        {
            return <div className="text-center mt-5 mb-5">
                <div className="font_2 weight_500 mt-2"> KORPA JE PRAZNA </div>
            </div>;
        }
        let total = 0;
        return <div>
            <SettingsButton path={this.props.path} />

            <div className="table-responsive mb-5">
                <Looper path={`${this.props.path}.title`} />

                <table className="table table-bordered">
                    <thead className="user_bcg">
                    <tr className="font-weight-bold">
                        <td width={140} className="text-center"> Slika </td>
                        <td> Ime </td>
                        <td className="d-none d-md-table-cell"> Cena </td>
                        <td width={90} className="text-center d-none d-md-table-cell">
                            Količina
                        </td>
                        <td width={160} className="d-none d-md-table-cell"> Ukupno </td>
                        <td width={80} className="text-center d-none d-md-table-cell">
                            Uklonite
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.products.map((item) => {
                        let extraPrice = 0;
                        const filters = Object.values(item.filters);
                        for(let i=0; i<filters.length; i++)    {
                            if(!filters[i].price) continue;
                            extraPrice = extraPrice + parseFloat(filters[i].price);
                        }
                        let fullPrice = (parseFloat(processPrice(item.product)) + extraPrice) * item.qty;
                        total += fullPrice;
                        return <tr key={item.unique}>
                            <td className="basket_image">
                                <img src={storage(item.product.image_name)} alt={item.product.name} className="img-fluid" />
                            </td>
                            <td className="align-middle">
                                <div className="font-weight-bold mb-3">
                                    <Link className="prewrap" to={routes.singleProduct.route({id: item.product.id})}>{item.product.name}</Link>
                                </div>

                                <div className="d-md-none">
                                    <div className="mb-3">
                                        <Price price={item.product.price} />
                                        <div className="font-weight-bold font_primary font_2">
                                            Ukupno: <Price price={fullPrice} />
                                        </div>
                                    </div>

                                    {item.product.categories[0] && item.product.categories[0].options.map((option) => {
                                        return <div key={option.id}>
                                            {option.elements.map((element) => {
                                                if(!item.filters[element.id]) return null;
                                                const El = elements[element.element].component;
                                                return  <div key={element.id} className="accent_bcg p-2 mb-2">
                                                    <El
                                                        item={element}
                                                        order={item.filters} />
                                                </div>
                                            })}

                                        </div>
                                    })}

                                    <Qty
                                        value={item.qty}
                                        max={10}
                                        onChange={(val) => this.changeQty(item.product.id, val)}/>
                                    <div className="mt-3">
                                        <button className="btn btn-outline-primary btn-sm" onClick={() => this.delete(item)}>
                                            <i className="fa fa-trash" /> Uklonite
                                        </button>
                                    </div>
                                </div>
                            </td>

                            <td className="align-middle d-none d-md-table-cell">
                                <div className="font_primary font-weight-bold mb-1">
                                    <Price item={item.product} />
                                </div>

                                {item.product.categories[0] && item.product.categories[0].options.map((option) => {
                                    return <div key={option.id}>
                                        {option.elements.map((element) => {
                                            if(!item.filters[element.id]) return null;
                                            const El = elements[element.element].component;
                                            return  <div key={element.id} className="accent_bcg p-2 mb-2">
                                                <El
                                                    item={element}
                                                    order={item.filters} />
                                            </div>
                                        })}

                                    </div>
                                })}
                            </td>
                            <td className="align-middle text-center d-none d-md-table-cell">
                                <Qty
                                    value={item.qty}
                                    max={10}
                                    onChange={(val) => this.changeQty(item.unique, val)}/>
                            </td>
                            <td className="align-middle d-none d-md-table-cell">
                                <div className="font-weight-bold font_primary">
                                    <Price price={fullPrice} />
                                </div>
                            </td>
                            <td className="align-middle text-center d-none d-md-table-cell">
                                <button className="btn btn-link" onClick={() => this.delete(item)}>
                                    <i className="fa fa-trash" />
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                    <tfoot>
                    <tr className="d-md-none bcg-2">
                        <td colSpan={2}>
                            <div className="p-4 font-weight-bold font_3 font_primary">
                                UKUPNO :  <Price price={total} />

                                <div className="mt-3">
                                    <Link to={routes.shop.route} className="btn btn-primary">
                                        Vrati se na kupovinu
                                    </Link>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr className="d-none d-md-table-row">
                        <td colSpan={3}>
                            <Link to={routes.shop.route} className="btn btn-primary">
                                Vrati se na kupovinu
                            </Link>
                        </td>
                        <td className="p-4 text-right align-middle font-weight-bold font_3">
                            Ukupno:
                        </td>
                        <td colSpan={2} className="p-4 align-middle">
                            <div className="font-weight-bold font_3 font_primary">
                                <Price price={total} />
                            </div>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>

            <div className={total < 500 ? "" : "d-none"}>
                <Looper path={`${this.props.path}.min order`} />
            </div>


            {total >= 500 &&
            <OrderForm fullPrice={total} path={this.props.path} />}
        </div>
    };

    componentDidMount() {
        if(this.props.basket.length === 0)      {
            this.setState({...this.state, products: [], loading: false});
            return false;
        }
        let basket = pluck(this.props.basket, "unique","id");
        getProducts({ids: Object.values(basket), options: true}).then((response) => {
            console.log(response.data.data)
            let holder = [];
            for(let j=0; j<this.props.basket.length; j++)      {
                let item = this.props.basket[j];
                const prod = response.data.data.find(prod => parseInt(prod.id) === parseInt(item.id));
                if(prod)        {
                    holder.push({...item, product: prod});
                }
                else        {
                    this.props.deleteFromBasket(item)
                }
            }
            this.setState({...this.state, products: holder, loading: false});
        });
    }
}
const mapStateToProps = state => ({
    basket: state.basket
});
const mapDispatchToProps = {
    setBasket: setBasket,
    deleteFromBasket: deleteFromBasket,
    emptyBasket: emptyBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(Basket);
