import React from "react";
import {getProduct} from "../../../providers/hooks";
import Loader from "../../../views/elements/Loader";
import Page404 from "../../../views/elements/Page404";
import {calculateDiscount, categoryBreadcrumbs} from "../../../providers/helpers";
import {connect} from "react-redux";
import ProductGallery from "./ProductGallery";
import Price from "../../theme/Price";
import ProductRender from "../../theme/ProductRender";
import Qty from "./Qty";
import {addToBasket} from "../../../store/actions/basketActions";
import {openDrawer} from "../../../store/actions/drawersActions";
import routes from "../../../app/routes";
import Share from "./Share";
import {seoReset, seoTitle} from "../../../providers/seoProvider";
import SettingsButton from "../../fragments/SettingsButton";
import bag from "../../../assets/images/bag-white.png";
import Looper from "../../Looper";
import {Link} from "react-router-dom";
import elements from "../../../views/cms/options/elements/elements";


class Product extends React.Component {

    state = {
        loading: false,
        product: null,
        options: [],
        inBasket: false,
        qty: 1,
        files: {},
        order: {
        }
    }

    editField = (name, value, element) => {
        const holder = {};
        for(let key in this.state.order) {
            if (key == element.id) continue;
            if (element.id == this.state.order[key].parent) continue;
            holder[key] = this.state.order[key];
        }
        if(!value || value === "")  {

        }  else {
            holder[element.id] = {id: element.id, value: value, parent: element.parent, price: element.price};
        }
        this.setState({...this.state, order: holder});
    }

    addToBusket = (e) => {
        e.preventDefault();
        this.props.addToBasket({id: this.state.product.id, qty: this.state.qty, filters: this.state.order});
        this.setState({...this.state, qty: 1, inBasket: true});
        this.props.openDrawer("basket");
        let t = this;
        setTimeout(function () {
            t.setState({...t.state, inBasket: false});
        }, 3000);
    };

    render = () => {
        if(this.state.loading)      {
            return <Loader height={300} />
        }
        if(!this.state.product)     {
            return <Page404 />
        }
        const product = this.state.product;
        let extraPrice = 0;
        return <div>
            <SettingsButton path={this.props.path} />

            <h1 className="d-md-none text-center font_6 p-2 mb-3">
                {product.name}
            </h1>

            {product.categories.length > 0 &&
            <div className="mb-4 d-none d-md-block">
                <span className="mr-2">
                    <Link to={routes.index.route}>
                        Naslovna <i className="fa fa-angle-right ml-2" />
                    </Link>
                </span>
                <span className="mr-2">
                    <Link to={routes.shop.route}>
                        Shop <i className="fa fa-angle-right ml-2" />
                    </Link>
                </span>
                {categoryBreadcrumbs(product.categories[0].id, this.props.categories).map((item, i) => {
                    return <span key={item.id} className="mr-2">
                        <Link to={routes.singleCategory.route(item)}>
                            {item.name}
                            {product.slug !== item.slug &&
                            <i className="fa fa-angle-right ml-2" />}
                        </Link>
                    </span>
                })}
                <span className="font_08">{product.name}</span>
            </div>}

            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="mr-md-4 position-relative">
                        <div className="product_tags" style={{zIndex: 5, right: -12}}>
                            {product.discount_price && product.price &&
                            <div>-{calculateDiscount(product)}%</div>}
                            {product.new && <div>NOVO</div>}
                            {product.action && <div>AKCIJA</div>}
                        </div>

                        <ProductGallery product={product}  />

                        {product.video &&
                        <div className="">
                            <div className="builder_title font_4 mb_15 font-weight-bold">
                                <span>Pogledaj video za ovaj proizvod</span>
                            </div>
                            <iframe width="100%" height="315"
                                    src={`//www.youtube.com/embed/${product.video.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/)[2]}`}
                                    frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                        </div>}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="gray font_1">
                            <div className="d-md-inline-block mb-2 mb-md-0"> Šifra: {product.code} </div>
                            <span className="ml-2 mr-2 d-none d-md-inline-block"> | </span>
                            <div className="d-md-inline-block mb-2 mb-md-0">Designed by: {product.designed_by || "Print4party"}</div>
                        </div>
                    </div>

                    <h1 className="font-weight-bold d-none d-md-block font_4 mb-3 prewrap">{product.name}</h1>

                    <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center font-weight-bold">
                            <div className="font_secondary font_8 mb-3">
                                <Price item={product} />
                                {product.discount_price &&
                                <del className="gray ml-2 font-weight-normal font_2 d-block d-md-inline-block">
                                    <Price price={product.price} />
                                </del>}
                                {product.discount_message &&
                                <div className="gray font-italic font_09 font-weight-normal">{product.discount_message}</div>}
                            </div>
                            <div style={{color: "green"}}>
                                NA STANJU
                            </div>
                        </div>


                        {product.description &&
                        <div className="gray mb-3 prewrap">{product.description}</div>}

                        <form action="#" onSubmit={this.addToBusket}>
                            {this.state.options.map((item) => {
                                return <div key={item.id} className="p-3 mb-3 accent_bcg">
                                    {item.elements.map((element) => {
                                        const El = elements[element.element].component;
                                        return  <div key={element.id} className="option_mb mb-4">
                                            <El
                                                item={element}
                                                editField={this.editField}
                                                order={this.state.order} />
                                        </div>
                                    })}

                                </div>
                            })}

                            {Object.values(this.state.order).map((item) => {
                                if(!item.price) return "";
                                extraPrice = extraPrice + parseFloat(item.price);
                                return <div key={item.id}>
                                    + <Price price={item.price} />
                                </div>
                            })}


                            <div className="font-weight-bold mb-4 font_2">
                                Ukupno: <span className="font_secondary"> <Price item={product} extra={extraPrice} /> </span>
                            </div>

                            {this.state.inBasket
                                ?  <div className="alert alert-success">
                                    <div className="font_2 font-weight-bold"> Dodato u korpu </div>
                                </div>
                                : <div className="d-flex">
                                    <Qty
                                        max={100000}
                                        value={this.state.qty}
                                        onChange={(val) => this.setState({...this.state, qty: val})} />

                                    <button type="submit" className="btn btn-success pl-4 pr-4">
                                        <img src={bag} width={24} style={{marginTop: -5}} alt="Korpa" /> Dodajte u korpu
                                    </button>
                                </div>}
                        </form>

                        <div className="gray mt-3 mb-3">
                            Prava potrošača: Zagarantovana sva prava kupaca po osnovu zakona o zaštiti potrošača.
                        </div>

                        <Share product={product} />
                    </div>
                </div>
            </div>

            {product.recommendations.length > 0 &&
            <div>
                <Looper
                    path="globals.structure.product recommendations" />

                <div className="row no-gutters pt-3">
                    {product.recommendations.map((item) => {
                        return <div key={item.id} className="col-6 col-md-2">
                            <ProductRender product={item} />
                        </div>
                    })}
                </div>
            </div>}
        </div>
    };

    loadProduct = async () => {
        const id = this.props.match.params.id;
        if (!id) return false;
        this.setState({...this.state, loading: true, product: false});
        getProduct({id: id}).then((response) => {
            if(response.data.product)       {
                if(response.data.product.seo_title)        {
                    seoReset(response.data.product);
                } else  {
                    seoTitle(response.data.product.name);
                }
            }
            console.log(response.data)
            this.setState({...this.state, loading: false, product: response.data.product, options: response.data.options});
        })

    }
    componentDidMount() {
        this.loadProduct();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.id !== prevProps.match.params.id)            {
            this.loadProduct();
        }
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    addToBasket: addToBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(Product);
