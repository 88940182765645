import React from 'react';
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Page404 from "../views/elements/Page404";
import Builder from "../builder/Builder";
import {setMenu} from "../store/actions/menuActions";
import {setGlobals} from "../store/actions/globalsActions";
import {getCategories, getGlobals, getMenu} from "../providers/hooks";
import AdminMiddleware from "../providers/AdminMiddleware";
import routes from "./routes";
import Products from "../views/cms/products/Products";
import Product from "../views/cms/product/Product";
import Categories from "../views/cms/categories/Categories";
import Filters from "../views/cms/filters/Filters";
import Storage from "../views/cms/storage/Storage";
import {setCategoreis} from "../store/actions/categoriesActions";
import Drawer from "../views/framework/Drawer";
import {closeDrawer, filesDrawer} from "../store/actions/drawersActions";
import Modal from "../views/framework/Modal";
import GridsModal from "../builder/fragments/modals/GridsModal";
import ElementsModal from "../builder/fragments/modals/ElementsModal";
import Alert from "../views/elements/edit/Alert";
import ScrollToTop from "../providers/ScrollToTop";
import Error from "../views/elements/edit/Error";
import UserMiddleware from "../providers/UserMiddleware";
import UserGuestMiddleware from "../providers/UserGuestMiddleware";
import Login from "../views/cms/login/Login";
import AdminGuestMiddleware from "../providers/AdminGuestMiddleware";
import Administrators from "../views/cms/administrators/Administrators";
import Newsletters from "../views/cms/newsletters/Newsletters";
import Users from "../views/cms/users/Users";
import Orders from "../views/cms/orders/Orders";
import Order from "../views/cms/order/Order";
import Options from "../views/cms/options/Options";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-192630206-1');

class App extends React.Component {

    state = {
        loading: true
    }

    render = () => {
        if(this.state.loading)      {
            return ""
        }
        return <div>
            {this.props.globals.structure.fontLink &&
            <>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href={this.props.globals.structure.fontLink} rel="stylesheet" />
            </>}

            <Router>
                <div className="cms">
                    <Alert />
                    <Error />

                    <Drawer
                        width="90%"
                        reload={true}
                        show={this.props.drawers.files.status}
                        close={() => this.props.filesDrawer({status: false})}>

                        <Storage />
                    </Drawer>

                    <Drawer
                        width="90%"
                        reload={true}
                        show={this.props.drawers.products.status}
                        close={() => this.props.closeDrawer("products")}>

                        <Products inDrawer={true} />
                    </Drawer>

                    <Modal
                        width={960}
                        open={this.props.drawers.grids.status}
                        close={() => this.props.closeDrawer("grids")}>

                        <GridsModal />
                    </Modal>

                    <Modal
                        open={this.props.drawers.elements.status}
                        close={() => this.props.closeDrawer("elements")}>

                        <ElementsModal />
                    </Modal>
                </div>

                <ScrollToTop />
                <Switch>
                    <AdminMiddleware exact path="/cms" component={Products} />
                    <AdminGuestMiddleware exact path={routes.adminLogin.route} component={Login} />

                    <AdminMiddleware exact path={routes.products.route} component={Products} />
                    <AdminMiddleware exact path={routes.product.route(":id?")} component={Product} />
                    <AdminMiddleware exact path={routes.categories.route} component={Categories} />
                    <AdminMiddleware exact path={routes.filters.route} component={Filters} />
                    <AdminMiddleware exact path={routes.storage.route} component={Storage} />
                    <AdminMiddleware exact path={routes.administrators.route} component={Administrators} />
                    <AdminMiddleware exact path={routes.newsletters.route} component={Newsletters} />
                    <AdminMiddleware exact path={routes.users.route} component={Users} />
                    <AdminMiddleware exact path={routes.orders.route} component={Orders} />
                    <AdminMiddleware exact path={routes.order.route(":id?")} component={Order} />
                    <AdminMiddleware exact path={routes.options.route} component={Options} />

                    <Route exact path={
                        [...this.props.menu.map((item) => {
                            let url = `/${item.slug}`;
                            if(item.link) url = item.link;
                            if(item.category) {
                                url += `/:param1?/:param2?/:param3?/:param4?`;
                            }
                            return url;
                        }), "/"]
                    } component={Builder} />

                    <Route exact path="/proizvod/:id/:slug?" component={Builder} />
                    <Route exact path={routes.basket.route} component={Builder} />
                    <Route exact path={routes.thankYou.route(":code?")} component={Builder} />
                    <Route exact path={routes.orderStatus.route(":code?")} component={Builder} />

                    <UserGuestMiddleware exact path={routes.userLogin.route} component={Builder} />
                    <UserGuestMiddleware exact path={routes.userRegister.route} component={Builder} />
                    <UserGuestMiddleware exact path={routes.userPasswordRecovery.route} component={Builder} />
                    <UserMiddleware exact path={routes.userDashboard.route} component={Builder} />
                    <UserMiddleware exact path={routes.userOrders.route} component={Builder} />
                    <UserMiddleware exact path={routes.userNewsletter.route} component={Builder} />

                    <Route path="*" component={Page404} />
                </Switch>
            </Router>
        </div>
    }

    async componentDidMount() {
        try {
            const response = await Promise.all([getMenu(), getGlobals(), getCategories({active: true})]);
            this.props.setMenu(response[0].data);
            this.props.setGlobals(response[1].data.globals);
            //console.log(response[1].data.geo)
            this.props.setCategories(response[2].data);
            this.setState({...this.state, loading: false});
        } catch (error) {
            this.setState({...this.state, loading: false});
        }
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    menu: state.menu,
    drawers: state.drawers,
    globals: state.globals
});
const mapDispatchToProps = {
    setMenu: setMenu,
    setGlobals: setGlobals,
    setCategories: setCategoreis,
    filesDrawer: filesDrawer,
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
