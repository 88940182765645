import React from 'react';
import ReactDOM from 'react-dom';
import  './assets/css/bootstrap.min.css';
//import  './assets/css/bootstrap.scss';
import './assets/css/theme.scss';
import './assets/css/cms.scss';
import "./assets/css/framework.scss";
import "./assets/css/builder.scss";
import 'react-image-lightbox/style.css';
import './app/axiosIntercept';
//import * as serviceWorker from './app/serviceWorker';

import App from './app/App';

import { Provider } from "react-redux";
import store from "./store/store";

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
            <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

//serviceWorker.register();