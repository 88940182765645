import React from "react";
import {connect} from "react-redux";
import update from "react-addons-update";
import {getUser, postOrderForm} from "../../../providers/hooks";
import Looper from "../../Looper";
import {delivery_price, delivery_price_over, payment_method} from "../../../providers/orderOptions";
import Price from "../../theme/Price";
import {emptyBasket} from "../../../store/actions/basketActions";
import routes from "../../../app/routes";
import BankLogos from "./BankLogos";
import Drawer from "../../../views/framework/Drawer";
import {currency} from "../../../providers/currencyProvider";
import countries from "./countries";


class OrderForm extends React.Component {
    _isMounted = false;

    default = {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        zip: "",
        city: "",
        message: "",
        promo: "",
        payment_method: "",
        state: "Srbija",
        delivery_price: 280,

        delivery_name: "",
        delivery_lastname: "",
        delivery_phone: "",
        delivery_address: "",
        delivery_zip: "",
        delivery_city: "",
        delivery_state: "Srbija",
        delivery_delivery_price: 280,
    }
    state = {
        form: this.default,
        loading: false,
        deliveryAddress: false,
        states: countries,
        card: true,  // TEST PRIKAZIVANJE
        cardDrawer: false,
        agreed: false,
        agreedWarning: false,
        errors: {},
    };

    change = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        let newState = update(this.state, {form: {[field]: {$set: value}}});
        this.setState(newState);
    }
    changeState = (e) => {
        const val = e.target.value;
        const name = e.target.name;
        const state = this.state.states.find(item => item.name === val);
        let delState = "state";
        let delPrice = "delivery_price";
        if(name === "delivery_state") {
            delState = "delivery_state";
            delPrice = "delivery_delivery_price";
        }
        let newState = update(this.state, {form:
                {[delState]: {$set: state.name}, [delPrice]: {$set: state.delivery_price}, payment_method: {$set: ""}}});
        this.setState(newState);
    }

    submit = (e, cardDrawer=false) => {
        e.preventDefault();
        if(!this.state.agreed) {
            this.setState({...this.state, agreedWarning: true});
            return false;
        }
        if(this.state.form.payment_method === "3")       {
            if(!cardDrawer)   {
                this.setState({...this.state, cardDrawer: true});
                return false;
            }
        }
        this.setState({...this.state, loading: true, errors: {}});
        let basket = this.props.basket.map((item) => {
            return {id: item.id, qty: item.qty, filters: item.filters}
        });
        let form = {...this.state.form, basket: basket};
        if(this.props.user)     {
            form = {...form, user_id: this.props.user.id};
        }
        let deliveryState = this.state.deliveryAddress ? this.state.form.delivery_state : this.state.form.state;
        if(this.state.deliveryAddress)  {
           form = {...form, delivery_price: this.state.form.delivery_delivery_price};
        }
        if(deliveryState === "Srbija" && this.props.fullPrice >= delivery_price_over())      {
            form = {...form, delivery_price: 0};
        }
        postOrderForm(form).then((response) => {
            if(this.state.form.payment_method === "3")       {
                var form = document.createElement("form");
                form.action="https://ecommerce.raiffeisenbank.rs/rbrs/enter";
                form.method="POST";
                for(let key in response.data)       {
                    var i=document.createElement("input");
                    i.type="hidden";
                    i.name = key;
                    i.value = response.data[key];
                    form.appendChild(i);
                }
                document.body.appendChild(form);
                form.submit();
               this.setState({...this.state, loading: false});
               return false;
            }
            window.location.href = routes.thankYou.route(response.data.code);
        }).catch((errors) => {
          this.setState({...this.state, cardDrawer:false, errors: errors, loading: false})
        });
    }

    error = (name) => {
        if(this.state.errors[name]) {
            return <div className="alert alert-danger">
                {this.state.errors[name]}
            </div>
        }
    }

    render = () => {
        let form = this.state.form;
        let fullPrice = this.props.fullPrice;
        let deliveryPrice = this.state.deliveryAddress ? this.state.form.delivery_delivery_price : this.state.form.delivery_price
        let deliveryState = this.state.deliveryAddress ? this.state.form.delivery_state : this.state.form.state
        if(fullPrice >= delivery_price_over() && deliveryState === "Srbija")    {

        } else  {
            fullPrice = fullPrice + deliveryPrice;
        }
        return <div className="container mb-5">
            <Drawer width="80%" reload={true} show={this.state.cardDrawer}
                    close={() => this.setState({...this.state, cardDrawer: false})}>

                <div className="row m-0">
                    <div className="col-12 mb-3">
                        <div className="order_banks text-center">
                            <BankLogos />
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="mb-3">
                            <div className="font_6 mb-3 font-weight-bold"> Vaše informacije </div>
                            <div className="mb-2">
                                <span className="mr-2">Vaše ime:</span>
                                <span className="font_2 font-weight-bold">
                                    {form.name} {form.lastname}
                                </span>
                            </div>
                            <div className="mb-2">
                                <span className="mr-2">Vaš email:</span>
                                <span className="font_2 font-weight-bold">
                                    {form.email}
                                </span>
                            </div>
                            <div className="mb-2">
                                <span className="mr-2">Vaš telefon:</span>
                                <span className="font-weight-bold">
                                    {form.phone}
                                </span>
                            </div>
                            <div className="mb-2">
                                <span className="mr-2">Vaša adresa:</span>
                                <span className="font-weight-bold">
                                    {form.address}, {form.zip} {form.city} {form.state}
                                </span>
                            </div>
                            {form.message &&
                            <div className="mb-2">
                                <div className="">Dodatna poruka:</div>
                                <div className="font-weight-bold prewrap">{form.message}</div>
                            </div>}

                            {this.state.deliveryAddress &&
                            <div className="bg-light p-2 mt-3">
                                <div className="font_4 mb-2 font-weight-bold"> Isporuka na drugu adresu: </div>
                                <div className="mb-2">
                                    <span className="mr-2">Ime:</span>
                                    <span className="font-weight-bold">
                                    {form.delivery_name} {form.delivery_name}
                                </span>
                                </div>
                                {form.delivery_phone &&
                                <div className="mb-2">
                                    <span className="mr-2">Telefon:</span>
                                    <span className="font-weight-bold">
                                        {form.delivery_phone}
                                    </span>
                                </div>}
                                <div className="mb-2">
                                    <span className="mr-2">Adresa:</span>
                                    <span className="font-weight-bold">
                                    {form.delivery_address}, {form.delivery_zip} {form.delivery_city} {form.delivery_state}
                                </span>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div>
                            <div className="font_6 mb-3 font-weight-bold">Osnovne informacije o PRODAVCU</div>
                            <div className="mb-2">Pun naziv: <strong>Print 4 Party</strong></div>
                            <div className="mb-2">Adresa: <strong>Cara Dušana 11, Čačak 32000, Srbija</strong></div>
                            <div className="mb-2">Delatnost i šifra delatnosti: <strong>1729 – proizvodi od  papira i karatona</strong></div>
                            <div className="mb-2">Matični broj: <strong>66081915</strong></div>
                            <div className="mb-2">PIB: <strong>112407081</strong></div>
                            <div className="mb-2">Internet adresa: <strong>print4party.rs</strong> </div>
                            <div className="mb-2">Telefon: <strong>+381 065 346 7050</strong></div>
                            <div className="mb-2">E-mail: <strong>print4party.rs@gmail.com</strong></div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="font_6 mb-3 font-weight-bold"> Porudžbina  </div>
                        <table className="table table-bordered">
                            <tbody>
                            {/*<tr>
                                <td>Broj porudžbine:</td>
                                <td><div className="font_2 font-weight-bold"> {this.state.code} </div></td>
                            </tr>*/}
                            <tr>
                                <td>Trgovac:</td>
                                <td><div className="font_2 font-weight-bold">Print 4 party</div></td>
                            </tr>
                            <tr>
                                <td>Iznos za plaćanje:</td>
                                <td><div className="font_2 font-weight-bold">
                                    <Price price={fullPrice} />
                                </div></td>
                            </tr>
                            </tbody>
                        </table>
                        <button
                            disabled={this.state.loading}
                            onClick={(e) => this.submit(e, true)} className="btn btn-success btn-lg mb-2 btn-block font-weight-bold">
                            {this.state.loading ? "Procesuiram..." : "PRODUŽITE NA PLAĆANJE"}
                        </button>
                        <div className="text-black-50 font-italic">Bićete redirektovani na terminal za plaćanje</div>
                    </div>
                </div>
            </Drawer>



            <form action="/" method="POST" onSubmit={this.submit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="mb-3">
                            <Looper path={`${this.props.path}.title1`} />
                        </div>

                        <div className="primary_font font-italic font_08 mb-3">
                            Polja označena zvezdicom (*) su obavezna.
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Ime: <span className="primary">*</span><br />
                                    <input
                                        name="name" value={form.name || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                    {this.error("name")}
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Prezime: <span className="primary">*</span><br />
                                    <input
                                        name="lastname" value={form.lastname || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Email: <span className="primary">*</span><br />
                                    <input
                                        name="email" value={form.email || ""} onChange={this.change}
                                        type="email" className="form-control" required={true} />
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Telefon: <span className="primary">*</span><br />
                                    <input
                                        name="phone" value={form.phone || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                        </div>

                        <label className="w-100 mb-3">
                            Adresa: <span className="primary">*</span><br />
                            <input
                                name="address" value={form.address || ""} onChange={this.change}
                                type="text" className="form-control" required={true} />
                        </label>

                        <div className="row mb-3">
                            <div className="col-4 pr-0">
                                <label className="w-100">
                                    Poštanski br<span className="d-none d-md-inline-block">oj</span>: <span className="primary">*</span><br />
                                    <input
                                        name="zip" value={form.zip || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                            <div className="col-8">
                                <label className="w-100">
                                    Grad: <span className="primary">*</span><br />
                                    <input
                                        name="city" value={form.city || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                        </div>

                        <label className="w-100 mb-3">
                            Država: <span className="primary">*</span><br />
                            <select value={this.state.form.state}
                                name="state" className="form-control" onChange={this.changeState}>
                                {this.state.states.map((item) => {
                                    return <option key={item.name} value={item.name}>
                                        {item.name} {!this.state.deliveryAddress && "("+ item.delivery_price +" din)"}
                                    </option>
                                })}
                            </select>
                        </label>

                        <label className="w-100 mb-3">
                            Napomena:<br />
                            <textarea
                                name="message" value={form.message || ""} onChange={this.change}
                                rows={4} className="form-control" />
                        </label>

                        <div>
                            <div className="mb-2 font-weight-bold">
                                <label className="chbx">
                                    <input
                                        onChange={() => this.setState({...this.state, deliveryAddress: !this.state.deliveryAddress})}
                                        checked={this.state.deliveryAddress}
                                        type="checkbox" /><span />
                                    <span  className="ml-2">
                                        Isporuka na drugu adresu
                                    </span>
                                </label>
                            </div>

                            {this.state.deliveryAddress &&
                            <div className="user_bcg p-3">
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <label className="w-100">
                                            Ime: <span className="primary">*</span><br />
                                            <input
                                                name="delivery_name" value={form.delivery_name || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                            {this.error("name")}
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label className="w-100">
                                            Prezime: <span className="primary">*</span><br />
                                            <input
                                                name="delivery_lastname" value={form.delivery_lastname || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label className="w-100">
                                            Telefon: <br />
                                            <input
                                                name="delivery_phone" value={form.delivery_phone || ""} onChange={this.change}
                                                type="text" className="form-control" />
                                        </label>
                                    </div>
                                </div>

                                <label className="w-100 mb-3">
                                    Adresa: <span className="primary">*</span><br />
                                    <input
                                        name="delivery_address" value={form.delivery_address || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>

                                <div className="row mb-3">
                                    <div className="col-4 pr-0">
                                        <label className="w-100">
                                            Poštanski br<span className="d-none d-md-inline-block">oj</span>: <span className="primary">*</span><br />
                                            <input
                                                name="delivery_zip" value={form.delivery_zip || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <label className="w-100">
                                            Grad: <span className="primary">*</span><br />
                                            <input
                                                name="delivery_city" value={form.delivery_city || ""} onChange={this.change}
                                                type="text" className="form-control" required={true} />
                                        </label>
                                    </div>
                                </div>

                                <label className="w-100 mb-3">
                                    Država: <span className="primary">*</span><br />
                                    <select value={this.state.form.delivery_state}
                                        name="delivery_state" className="form-control" onChange={this.changeState}>
                                        {!this.state.form.delivery_state &&  <option value=""> </option>}
                                        {this.state.states.map((item) => {
                                            return <option key={item.name} value={item.name}>
                                                {item.name} ({item.delivery_price} din)
                                            </option>
                                        })}
                                    </select>
                                </label>
                            </div>}
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="mb-4">
                            <Looper path={`${this.props.path}.title2`} />
                        </div>

                        <div className="mb-4 font_2">
                            <Looper path={`${this.props.path}.title3`} />

                            {deliveryState === "Srbija" &&
                            <>
                                <div className="mb-2 mt-2">
                                    <label className="p-3 w-100 user_bcg">
                                        <input type="radio" name="payment_method" value={1} required={true}
                                               onChange={this.change}
                                               checked={form.payment_method === "1"} /> {payment_method(1)}
                                        <div className="font_09 mt-1 font-italic">Plaćanje prilikom preuzimanja paketa kurirskoj službi</div>
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <label className="p-3 w-100 user_bcg">
                                        <input type="radio" name="payment_method" value={2}
                                               onChange={this.change} required={true}
                                               checked={form.payment_method === "2"} /> {payment_method(2)}
                                        <div className="font_09 mt-1 font-italic">Nakon što završite proces
                                            naručivanja na vašu e-mail adresu će stići informacija sa potrebnim
                                            podacima za uplatu. Uplatu možete izvršiti u svim bankama i poštama Srbije</div>
                                    </label>
                                </div>
                            </>}
                            {this.state.card &&
                            <div className="p-3 user_bcg">
                                <label className="w-100">
                                    <input type="radio" name="payment_method" value={3}
                                           onChange={this.change} required={true}
                                           checked={form.payment_method === "3"} /> {payment_method(3)}
                                    <div className="font_09 mt-1 font-italic">
                                        Sigurnost plaćanja je zagarantovana. Kompletan proces
                                        plaćanja karticama se obavlja kroz online sistem banke.
                                        Print4party nema pristup podacima sa vaše kartice.
                                    </div>
                                </label>
                                <div className="order_banks text-center">
                                    <BankLogos />
                                </div>
                            </div>}
                        </div>

                        <div className="mb-4">
                            <div className="mb-3">
                                <Looper path={`${this.props.path}.title4`} />
                            </div>


                            <div className="user_bcg p-3 mb-3">
                                Dostava: <br/>
                                {deliveryState === "Srbija" &&
                                <div className="font-italic"> (za porudžbine preko {delivery_price_over()} {currency("RSD")} je besplatna) </div>}
                                <div className="font_primary mb-2 font_4 font-weight-bold">
                                {(this.props.fullPrice >= delivery_price_over() && deliveryState === "Srbija")
                                    ? <div>0 {currency("RSD")}</div>
                                    : <div> + <Price price={deliveryPrice} /></div>}
                                </div>
                            </div>

                            <div className="font_primary mb-2 font_4 font-weight-bold">
                                <Price price={fullPrice} />
                            </div>
                        </div>

                        <div>
                            {this.error("message")}
                        </div>


                        <div className="mb-2">
                            <label className="chbx p-1" style={{border: (this.state.agreedWarning && !this.state.agreed) ? "4px solid red" : ""}}>
                                <input type="checkbox"
                                       onChange={() => this.setState({...this.state, agreed: !this.state.agreed})}
                                       checked={this.state.agreed} /><span />
                                &nbsp; Potvrdite da se slažete sa &nbsp;
                                <a href="/uslovi-online-kupovine" style={{color: "#d40000"}} target="popup"
                                   onClick={() => window.open("/uslovi-online-kupovine" ,'name','width=600,height=600')}>
                                    uslovima online kupovine
                                </a>
                            </label>
                        </div>

                        {form.payment_method === "3"
                            ? <button className="btn btn-lg font-weight-bold btn-success mr-3">
                                <i className="fas fa-credit-card" />
                                <span className="ml-2">PRODUŽITE NA PLAĆANJE</span>
                            </button>
                            :<button disabled={this.state.loading} className="btn btn-lg font-weight-bold btn-success mr-3">
                                <span className="pl-4 pr-4">PORUČITE</span>
                            </button>}

                        {this.state.loading &&
                        <span> Procesuiram... </span>}
                    </div>
                </div>
            </form>
        </div>
    };

    componentDidMount() {
        this._isMounted = true;


        const query = window.location.search; // could be '?foo=bar'
        const params =  new URLSearchParams(decodeURIComponent(query));
        //let card = params.get('card') ? params.get('card') : false;
        //this.setState({...this.state, card: card});

        if(!this.props.user)    {
            return false;
        }
        getUser().then((response) => {
            if(!this._isMounted) return false;
            const fill = {...this.default, ...response.data};
            const newState = update(this.state, {form: {$set: fill}})
            this.setState(newState);
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

const mapStateToProps = state => ({
    user: state.user,
    basket: state.basket,
    categories: state.categories
});
const mapDispatchToProps = {
    emptyBasket: emptyBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
