import React from "react";
import Loader from "../../elements/Loader";
import update from "react-addons-update";
import {getProduct, putProduct} from "../../../providers/hooks";
import Input from "../../elements/edit/Input";
import Checkbox from "../../elements/edit/Checkbox";
import routes from "../../../app/routes";
import Image from "../../elements/edit/Image";
import Files from "./Files";
import Categories from "./Categories";
import Recommendations from "./Recommendations";
import Textarea from "../../elements/edit/Textarea";


class Product extends React.Component {

    default = {
        id: false,
        name: "",
        slug: "",
        code: "",
        action: null,
        new: null,
        image_id: null,
        seo_title: null,
        seo_description: null,
        seo_keywords: null,
        images: [],
        sizes: [],
        categories: [],
        filters: [],
        recommendations: []
    }
    state = {
        loading: true,
        product: {},
        errors: false
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        if(e.target.type === "checkbox")    {
            if(!e.target.checked)   {
                value = null;
            }
        }
        this.setState(update(this.state, {product: {[field]: {$set: value}} }));
    }

    submit = (e) => {
        e.preventDefault();
        putProduct(this.state.product).then((response) => {
            this.props.history.push(routes.products.route);
        }).catch((errors) => {
            console.log(errors)
            this.setState({...this.state, errors: errors});
        });
    }

    addSize = () => {
        const obj = {name: "", code: "", price: ""};
        this.setState(update(this.state, {product: {sizes: {$push: [obj]}} }));
    }

    form = () => {
        const product = this.state.product;
        return <div className="container mt-5 mb-5">

            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Osnovne informacije </div>
                <div className="dark_block">
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <div className="border">
                                <Image
                                    api={this.props.api}
                                    path={product.id && `products.${product.id}.image_id`}
                                    onChange={this.editField}
                                    value={product.image_name}
                                    name="image_id" />
                            </div>
                            <div className="text-center font-italic font_09"> Kliknite na sliku da promenite </div>
                        </div>
                        <div className="col-md-9">
                            <div className="mb-3">
                                <label className="d-block">
                                    <div className="mb-2"> Ime: </div>
                                    <Textarea
                                        path={product.id && `products.${product.id}.name`}
                                        onChange={this.editField}
                                        value={product.name} name="name" maxLength={191} required />
                                </label>
                            </div>

                            <div className="mb-3">
                                <label className="d-block">
                                    <div className="mb-2"> Slug: </div>
                                    <Input
                                        path={product.id && `products.${product.id}.slug`}
                                        onChange={this.editField}
                                        update={!product.id && product.name}
                                        slugify={true}
                                        value={product.slug} name="slug" maxLength={191} required />
                                </label>
                            </div>


                            <div>
                                <label className="d-block">
                                    <div className="mb-2"> Šifra: </div>
                                    <Input
                                        path={product.id && `products.${product.id}.code`}
                                        onChange={this.editField}
                                        value={product.code} name="code"
                                        maxLength={16} autoComplete="off"  />
                                </label>
                            </div>

                            <div>
                                <label className="d-block">
                                    <div className="mb-2"> Designed by: </div>
                                    <Input
                                        path={product.id && `products.${product.id}.designed_by`}
                                        onChange={this.editField}
                                        value={product.designed_by} name="designed_by"
                                        maxLength={191} autoComplete="off"  />
                                </label>
                            </div>

                            <div>
                                <label className="d-block">
                                    <div className="mb-2"> Kratak opis: </div>
                                    <Textarea
                                        path={product.id && `products.${product.id}.description`}
                                        onChange={this.editField}
                                        value={product.description} name="description"
                                         />
                                </label>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 text-center"> </div>
                        <div className="col-md-4 text-center">
                            <Checkbox
                                path={product.id && `products.${product.id}.action`}
                                checked={product.action} name="action"
                                onChange={this.editField}
                                appendTrue={<span className="green font-weight-bold">AKCIJA</span>}
                                appendFalse={<span className="">AKCIJA</span>} />
                        </div>
                        <div className="col-md-4 text-center">
                            <Checkbox
                                path={product.id && `products.${product.id}.new`}
                                checked={product.new} name="new"
                                onChange={this.editField}
                                appendTrue={<span className="green font-weight-bold">NOVO</span>}
                                appendFalse={<span className="">NOVO</span>} />
                        </div>
                    </div>
                </div>
            </div>



            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> SEO </div>
                <div className="dark_block">
                    <label className="d-block mb-3">
                        <div className="mb-2"> SEO title: </div>
                        <Input
                            path={product.id && `products.${product.id}.seo_title`}
                            onChange={this.editField}
                            value={product.seo_title} name="seo_title"
                            autoComplete="off"  />
                    </label>
                    <label className="d-block mb-3">
                        <div className="mb-2"> SEO description: </div>
                        <Input
                            path={product.id && `products.${product.id}.seo_description`}
                            onChange={this.editField}
                            value={product.seo_description} name="seo_description"
                            autoComplete="off"  />
                    </label>
                    <label className="d-block mb-3">
                        <div className="mb-2"> SEO keywords: </div>
                        <Input
                            path={product.id && `products.${product.id}.seo_keywords`}
                            onChange={this.editField}
                            value={product.seo_keywords} name="seo_keywords"
                            autoComplete="off"  />
                    </label>
                </div>
            </div>


            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Cena </div>
                <div className="dark_block">
                    <div className="row">
                        <div className="col">
                            <strong>Cena</strong>
                            <div className="form-inline">
                                <div className="input-group">
                                    <Input
                                        path={product.id && `products.${product.id}.price`}
                                        onChange={this.editField}
                                        value={product.price} name="price"
                                        price={true}
                                        maxLength={191} />
                                    <div className="input-group-append">
                                        <span className="input-group-text font-weight-bold">din</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <strong>Snižena cena</strong>
                            <div className="form-inline">
                                <div className="input-group">
                                    <Input
                                        path={product.id && `products.${product.id}.discount_price`}
                                        onChange={this.editField}
                                        value={product.discount_price} name="discount_price"
                                        price={true}
                                        maxLength={191} />
                                    <div className="input-group-append">
                                        <span className="input-group-text font-weight-bold">din</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Veličine </div>
                <div className="dark_block">
                    <Sizes
                        product={product.id}
                        items={product.sizes}
                        onChange={this.editField} />

                    <button type="button"
                            onClick={this.addSize}
                            className="btn btn-primary">
                        <i className="fa fa-plus" /> Dodajte veličinu
                    </button>
                </div>
            </div>*/}

            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Kategorije </div>
                <div className="dark_block">
                <Categories
                    product={product.id}
                    selected={product.categories}
                    onChange={this.editField} />
            </div>
            </div>

            {/*
            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Filteri </div>
                <div className="dark_block">
                    <Filters
                        product={product.id}
                        selected={product.filters}
                        onChange={this.editField} />
                </div>
            </div>*/}

            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Preporučeni proizvodi </div>
                <div className="dark_block p-3">
                    <Recommendations
                        name="recommendations"
                        value={product.recommendations}
                        product={product.id}
                        onChange={this.editField} />
                </div>
            </div>

            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Youtube video </div>
                <div className="dark_block p-3">
                    <label className="d-block mb-3">
                        <div className="mb-2"> Prekopirajte youtube link: </div>
                        <Input
                            link={true}
                            path={product.id && `products.${product.id}.video`}
                            onChange={this.editField}
                            value={product.video} name="video"
                            autoComplete="off"  />
                    </label>
                </div>
            </div>

            <div className="mb-4">
                <div className="font-weight-bold mb-1 font_3"> Galerija </div>
                <div className="dark_block p-3">
                    <Files
                        api={this.props.api}
                        name="images"
                        value={product.images}
                        product={product.id}
                        path={product.id && `product_images`}
                        onChange={this.editField} />
                </div>
            </div>
        </div>
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mb-5">
            {this.state.product.id
                ? this.form()
                : <form action="#" onSubmit={this.submit}>
                    {this.form()}
                    <button type="submit" className="btn btn-primary">Sačuvajte</button>
                </form>}
        </div>
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        if(!id) {
            this.setState({...this.state, loading: false, product: this.default});
            return false;
        }
        getProduct({id: id}).then((response) => {
            this.setState({...this.state, loading: false, product: response.data.product});
        });
    }
}

export default Product;
